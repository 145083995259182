export const routes = [
    {
        path: '/expenses',
        name: 'expenses.browse',
        component: () => import(/* webpackChunkName: "ExpensesBrowse" */ '@/views/app/ExpensesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/expenses/create',
        name: 'expenses.create',
        component: () => import(/* webpackChunkName: "ExpensesActions" */ '@/views/app/ExpensesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/expenses/:id/edit',
        name: 'expenses.edit',
        component: () => import(/* webpackChunkName: "ExpensesActions" */ '@/views/app/ExpensesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/expenses/:id/delete',
        name: 'expenses.delete',
        component: () => import(/* webpackChunkName: "ExpensesActions" */ '@/views/app/ExpensesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]